import { AuthProviders } from '@/components/site/AuthPage'
import supabase from '@/lib/utils/supabase'
import { Session } from '@supabase/supabase-js'
import { usePostHog } from 'posthog-js/react'
import { useEffect, useState } from 'react'

export const useSession = () => {
  const [session, setSession] = useState<Session | null>(null)
  const posthog = usePostHog()

  useEffect(() => {
    const fetchSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession()

      setSession(session)

      const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
        if (!session) {
          posthog.reset()
        }

        if (session) {
          const loginMethod = (session.user.app_metadata.provider || 'email') as AuthProviders
          localStorage.setItem('lastLoginMethod', loginMethod)

          posthog.identify(session.user.id, {
            email: session.user.email,
          })
        }
      })

      return () => {
        authListener.subscription.unsubscribe()
      }
    }

    fetchSession()
  }, [])

  return session
}
