import { useSession } from '@/hooks/use-session'
import Intercom from '@intercom/messenger-js-sdk'
import { useEffect } from 'react'

export function IntercomMessenger() {
  const session = useSession()

  useEffect(() => {
    // Initialize Intercom with user data if available
    if (session?.user) {
      Intercom({
        app_id: 'esgw7ib6',
        user_id: session.user.id,
        email: session.user.email,
        created_at: Math.floor(new Date(session.user.created_at).getTime() / 1000),
      })
    } else {
      // Initialize Intercom for anonymous users
      Intercom({
        app_id: 'esgw7ib6',
      })
    }
  }, [session?.user])

  return null
}
