import { cn } from '@/lib/utils'
import React from 'react'

export type InputProps = {
  label?: string
  helperText?: string
  error?: boolean
  success?: boolean
  disabled?: boolean
  className?: string
  absoluteHelperText?: boolean // Added absoluteHelperText prop
} & React.InputHTMLAttributes<HTMLInputElement>

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, helperText, error, success, disabled, className, absoluteHelperText, ...rest }, ref) => {
    const inputClassName = cn(
      'w-full rounded-md py-1.5 pl-1.5 text-md shadow-inner focus:outline-primary-200 focus:bg-light-300 bg-light-100 hover:bg-light-300 ',
      'placeholder:text-light-600 text-light-900 box-border ', // Default
      disabled && 'bg-light-100 text-light-500 cursor-not-allowed', // Disabled
      className
    )

    const helperClassName = cn('text-xs', error ? 'text-red-500' : success ? 'text-green-500' : 'text-light-500')

    return (
      <div className="relative flex w-full flex-col gap-2">
        {label && <label className="text-sm text-light-700">{label}</label>}
        <input ref={ref} className={inputClassName} disabled={disabled} {...rest} />
        {helperText && (
          <p className={`${helperClassName} ${absoluteHelperText ? 'absolute top-10' : ''}`}>{helperText}</p>
        )}
      </div>
    )
  }
)

Input.displayName = 'Input'
