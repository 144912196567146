import { getNormalizedEmail } from '../components/site/helpers'

export interface WaitlistSignupResponse {
  amount_referred: number
  answers?: Array<{
    question_value: string
    optional: boolean
    answer_value: string | null
  }>
  created_at: string
  email: string
  priority: number
  referral_link: string
  referral_token: string
  referred_by_signup_token: string | null
  removed_date: string | null
  removed_priority: number | null
  uuid: string
  verified: boolean
  waitlist_id: number
}

interface WaitlistError {
  error_code: string
  error_string: string
  status_code: number
}

const WAITLIST_ID = 21552
const API_BASE_URL = 'https://api.getwaitlist.com/api/v1'

export async function checkWaitlistStatus(
  email: string,
  shouldSignupUser?: boolean
): Promise<WaitlistSignupResponse | null> {
  const normalizedEmail = getNormalizedEmail(email)
  const response = await fetch(
    `${API_BASE_URL}/signup?waitlist_id=${WAITLIST_ID}&email=${encodeURIComponent(normalizedEmail)}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  if (!response.ok) {
    const error = await response.json() as WaitlistError
    if (error.error_code === 'NO_SIGNUP_FOUND') {
      if (shouldSignupUser) {
        return signupForWaitlist(normalizedEmail)
      }
      return null
    }
    throw new Error('Failed to check waitlist status')
  }

  return response.json()
}

export function isApproved(status: WaitlistSignupResponse): boolean {
  return status.removed_date !== null && status.removed_priority !== null
}

export async function signupForWaitlist(email: string): Promise<WaitlistSignupResponse> {
  const normalizedEmail = getNormalizedEmail(email)
  const response = await fetch(`${API_BASE_URL}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: normalizedEmail.trim(),
      waitlist_id: WAITLIST_ID,
      referral_link: window.location.href,
    }),
  })

  if (!response.ok) {
    throw new Error('Failed to join waitlist')
  }

  return response.json()
}

export async function getReferrerInfo(refToken: string): Promise<WaitlistSignupResponse | null> {
  const response = await fetch(
    `${API_BASE_URL}/signup/referrer?waitlist_id=${WAITLIST_ID}&ref_token=${refToken}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  if (!response.ok) {
    return null
  }

  return response.json()
} 