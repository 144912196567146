import { JoobaLogo } from './JoobaLogo'

const Loading = () => (
  <div className="flex h-screen items-center justify-center bg-light-50">
    <div className="animate-pulse opacity-70">
      <JoobaLogo type="logo" size="lg" />
    </div>
  </div>
)

export default Loading
