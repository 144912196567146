import { CamelCasedResumeTable } from '@/lib/types'
import { toCamelCase, toSnakeCase } from '@/lib/utils'
import supabase from '@/lib/utils/supabase'

export async function fetchSingleResume(userId: string, resumeId: string): Promise<CamelCasedResumeTable> {
  const { data, error } = await supabase.from('resumes').select('*').eq('user_id', userId).eq('id', resumeId).single()

  if (error) throw error

  return toCamelCase(data)
}
export async function fetchSortedUserResumes(userId: string): Promise<CamelCasedResumeTable[]> {
  const { data, error } = await supabase.from('resumes').select('*').eq('user_id', userId)

  if (error) throw error

  const sortedData = data
    .map(toCamelCase)
    .sort((resumeA, resumeB) => new Date(resumeB.openedAt).getTime() - new Date(resumeA.openedAt).getTime())

  return sortedData
}

export async function updateResume(userId: string, resumeId: string, fieldsToUpdate: Partial<CamelCasedResumeTable>) {
  const { data, error } = await supabase
    .from('resumes')
    .update(toSnakeCase(fieldsToUpdate))
    .eq('user_id', userId)
    .eq('id', resumeId)

  if (error) throw error

  return data
}
