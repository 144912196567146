import { useCurrentUser } from '@/context/UserContext'

import { queryOptions, skipToken, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { fetchSingleResume, fetchSortedUserResumes } from './helpers'

export const queryFactory = {
  resumes: (userId: string) =>
    queryOptions({
      queryKey: ['resumes', userId],
      queryFn: () => fetchSortedUserResumes(userId),
      staleTime: Infinity,
    }),
  singleResume: (userId: string, resumeId: string | undefined) =>
    queryOptions({
      queryKey: ['resumes', userId, resumeId],
      queryFn: resumeId ? () => fetchSingleResume(userId, resumeId) : skipToken,
      staleTime: Infinity,
    }),
}

export const useResumeQueries = () => {
  const queryClient = useQueryClient()
  const { resumeId: paramResumeId } = useParams()
  const user = useCurrentUser()

  const useAllResumes = () => useQuery(queryFactory.resumes(user.id))
  const useSingleResume = (overrideResumeId?: string) => {
    const resumeId = overrideResumeId || paramResumeId
    return useQuery({
      ...queryFactory.singleResume(user.id, resumeId),
      initialData: () => {
        const queryKey = queryFactory.resumes(user.id).queryKey
        return queryClient.getQueryData(queryKey)?.find(resume => resume.id === resumeId)
      },
    })
  }

  return { useAllResumes, useSingleResume }
}
