import Loading from '@/components/ui/Loading'
import { useSession } from '@/hooks/use-session'
import { checkWaitlistStatus, isApproved, WaitlistSignupResponse } from '@/lib/waitlist'
import { Session } from '@supabase/supabase-js'
import { createContext, useContext, useEffect, useState } from 'react'

type UserContextType = {
  session: Session | null
  waitlistStatus: WaitlistSignupResponse | null
  isLoading: boolean
}

export const UserContext = createContext<UserContextType | null>(null)

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const session = useSession()
  const [waitlistStatus, setWaitlistStatus] = useState<WaitlistSignupResponse | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchWaitlistStatus = async () => {
      if (session?.user?.email) {
        try {
          setIsLoading(true)
          const status = await checkWaitlistStatus(session.user.email, true)
          setWaitlistStatus(status)
        } finally {
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
      }
    }

    fetchWaitlistStatus()
  }, [session])

  if (isLoading) {
    return <Loading />
  }

  return <UserContext.Provider value={{ session, waitlistStatus, isLoading }}>{children}</UserContext.Provider>
}

export const useCurrentUser = () => {
  const context = useContext(UserContext)
  if (!context?.session) throw new Error('No session found')

  return context.session.user
}

// Updated helper hook to use new isApproved function
export const useIsUserApprovedOnWaitlist = () => {
  const context = useContext(UserContext)
  if (!context?.waitlistStatus) return false

  return isApproved(context.waitlistStatus)
}
