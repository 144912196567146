import JoobaLogoSmall from '@/assets/jooba-logo-small-dark.svg?react'
import JoobaWordmarkSmall from '@/assets/jooba-wordmark-small-dark.svg?react'

interface JoobaLogoProps {
  type?: 'logo' | 'wordmark'
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

const sizeClasses = {
  sm: 'h-6',
  md: 'h-8',
  lg: 'h-10',
} as const

export function JoobaLogo({ type = 'logo', size = 'md', className = '' }: JoobaLogoProps) {
  const LogoComponent = type === 'wordmark' ? JoobaWordmarkSmall : JoobaLogoSmall

  return (
    <div className={className}>
      <LogoComponent className={`pointer-events-none w-auto select-none ${sizeClasses[size]}`} />
    </div>
  )
}
